import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import Link from '../components/Common/Link'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'
import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const OperationalPhase = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)
  return (
    <>
      <SEO
        title="Operational phase"
        description="In the project's operational stage, the focus shifts to the construction project's ongoing maintenance and operation. Digital twins are employed to replicate physical assets virtually, allowing for real-time monitoring and analysis. This enables predictive maintenance, where potential issues can be identified and addressed before they lead to costly disruptions, ensuring optimal performance."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            May 13, 2024
          </Typography>
          <Typography variant="h1" color="blue" size={40} mb={48}>
            Operational phase
          </Typography>
          <Typography variant="body1" size={18}>
            In the project's operational stage, the focus shifts to the
            construction project's ongoing maintenance and operation. Digital
            twins are employed to replicate physical assets virtually, allowing
            for real-time monitoring and analysis. This enables predictive
            maintenance, where potential issues can be identified and addressed
            before they lead to costly disruptions, ensuring optimal
            performance.
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Digital Twin Development
              </Typography>
              <Typography variant="body1" size={18}>
                The Digital Twin Development service offered by SIGNAX
                construction experts seamlessly integrates technical and
                as-built documentation into the model's elements, achieving a{' '}
                <Link
                  href="https://bimforum.org/resource/lod-level-of-development-lod-specification"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Level of Development (LOD)
                </Link>{' '}
                500. Our team of experts, including BIM and IT specialists,
                integrates sensor data into your project's BIM model for optimal
                project operation. LOD 500 ensures that the model includes
                precise geometry, accurate information, and specific details
                necessary for operations and maintenance. Incorporating sensor
                data enables real-time monitoring and analysis, transforming
                information from sensors into insightful graphs displayed on
                intuitive dashboards. For example, regular monitoring and
                control of pump and compressor maintenance saves on personnel
                (minimizing the number of mechanic inspections), reduces repair
                costs, and lowers the overall building operational expenses.
                Similarly, implementing automated systems for monitoring
                electrical systems can detect potential issues before they
                escalate, preventing costly downtime and reducing the risk of
                electrical failures. Temperature control in distribution panels
                ensures that electrical components operate within safe
                temperature limits, mitigating the risk of overheating and fire
                hazards. By integrating these proactive measures, buildings can
                enhance safety, extend equipment lifespan, and optimize
                operational efficiency.
              </Typography>
            </div>
            <div className="flex flex-col">
              <StaticImage
                width={564}
                className="mb-8"
                src="../assets/images/solutions/operational-phase/image-1.png"
                alt="Digital Twin Development"
                title="Digital Twin Development"
                placeholder="blurred"
              />
              <StaticImage
                width={564}
                src="../assets/images/solutions/operational-phase/image-2.png"
                alt="Digital Twin Development 2"
                title="Digital Twin Development 2"
                placeholder="blurred"
              />
            </div>
          </div>
          <Typography variant="h2" size={24} mb={16}>
            Asset information storage and management
          </Typography>
          <Typography variant="body1" size={18}>
            The Asset Information Storage and Management service for BIM models
            streamlines project data management by storing all essential
            information within SIGNAX DOCS. This service facilitates easy
            linking of files to model elements, ensuring seamless integration of
            technical and as-built documentation. The project digital library
            enables rapid asset data extraction, information transformation, and
            efficient management, enhancing overall project coordination and
            decision-making.
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Virtual Training
              </Typography>
              <Typography variant="body1" size={18}>
                The Virtual training service for construction projects offers a
                practical and accessible approach to staff training and better
                project familiarization. Integrating AR/VR/MR technologies with
                BIM models enhances the virtual training service. Participants
                can engage in immersive scenarios designed for their specific
                roles and responsibilities, such as hands-on simulations of
                assembling or repairing expensive equipment. Additionally, they
                can explore virtual environments to study evacuation routes
                during emergencies like fires or earthquakes. These interactive
                experiences provide invaluable training opportunities, allowing
                personnel to develop critical skills in a safe and controlled
                environment and accelerate onboarding into the project.
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/operational-phase/image-3.png"
                alt="Virtual Training"
                title="Virtual Training"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate('/bim-services/')}
          >
            Other BIM services
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default OperationalPhase

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
